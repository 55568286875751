<template>
  <div class="page1">
      <EForm
              :formColumns="formColumns"
              :rowSize="4"
              :optionsBtn="true"
              :actionBtn="false"
              :formData="searchForm"
              ref="form"
              @onSearch="onSearch"
              :searchFlag="true"
              :exportShow="false"
      ></EForm>
      <ETable :tableTitle="tableTitle"
              :tableData="tableData"
              :needPagination="true"
              :count="count"
              @changePage="changePage"
              @changeSize="changeSize"
              :page="searchForm.current"
              :page_size="searchForm.size">
          
      </ETable>
      
  </div>
</template>

<script>
import ETable from '@/components/ETable'
import Http from "@/service/http";
import EDialog from '@/components/EDialog'
import EButton from '@/components/EButton'
import EForm from '@/components/EForm'
import vxRule from "@/assets/js/formValidate";
import {mapGetters} from 'vuex'

export default {
  name: 'Login',
  data() {
    return {
      formColumns: [
        {
          title: '请求IP',
          type: 'text',
          property: 'remoteIp',
          placeHolder: '请输入',
          show: true,
        },
        {
          title: '模块',
          type: 'text',
          property: 'model',
          placeHolder: '请输入',
          show: true,
        },],
      tableTitle: [
        {
          label: '请求IP',
          prop: 'remoteIp',
        },{
          label: '请求地址',
          prop: 'requestUri',
        }, {
          label: '参数',
          prop: 'params',
        }, {
          label: '模块',
          prop: 'model',
        }, {
          label: '操作时间',
          prop: 'createTime',
        }
      ],
      tableData: [],
      loading: false,
      dialogVisible: false,
      title: '添加账号',
      form: {
        name: '',
        username: '',//登录账号
        email: '',//邮箱
        mobile: '',//手机号
        status: 1,//状态
        password: '',//密码
        roleId: '',//角色ID
        deptId: '',//部门ID
        confirmPassword: ''
      },
      searchForm: {
        name: '',
        username: '',
        current: 1,
        size: 10
      },
      cascader: false,
      role_id: [],
      options: [],
      count: null,
      labelPosition: 'right',
      labelWidth: '100px',
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  watch: {},
  created() {
    this.getData()
  },
  components: {ETable, EDialog, EButton, EForm},
  beforeMount() {

  },
  methods: {
    onSearch() {
      this.searchForm.current = 1
      this.getData()
    },
    changePage(page) {
      this.searchForm.current = page
      this.getData()
    },
    changeSize(size) {
      this.searchForm.size = size
      this.getData()
    },
    async getData() {
      console.log(JSON.stringify(this.searchForm))
      let res = await Http.logList(this.searchForm)
      if (res.code == 200) {
        this.tableData = res.data.records
        this.count = res.data.total
      }

    },
  }
}
</script>

<style lang="scss" scoped>
  .title {
      font-size: 16px;
      padding: 10px 0;
      border-bottom: 1px solid #DCDFE6;
      margin-bottom: 20px;
      color: #333;
      font-weight: bold;

  }
</style>

